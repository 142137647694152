@use 'global' as *;
@use 'sass:math';

.intro {
    padding-block: rem(65) rem(80);
    @include pc() {
        padding-block: rem(80);
    }
}

.intro__inner {
    @include pc() {
        max-width: 930px;
    }
}

.intro__date {
    text-align: center;
    font-size: rem(20);
    font-weight: $bold;
    line-height: 1.6;
    @include sp() {
        margin-bottom: rem(16);
    }
    @include pc() {
        font-size: rem(32);
    }
    &Year {
        font-size: rem(15);
        @include pc() {
            font-size: rem(20);
            margin-right: rem(3);
        }
    }
    &Num {
        font-size: rem(28);
        @include pc() {
            font-size: rem(48);
            margin-inline: rem(3);
        }
    }
    &Period {
        margin-inline: rem(4) rem(2);
        @include pc() {
            margin-inline: rem(10) rem(7)
        }
    }
    &Day {
        background-color: $white;
        border-radius: 50%;
        color: $black;
        font-size: rem(14);
        padding: 0 rem(4) rem(2);
        margin-left: rem(2);
        line-height: rem(20);
        vertical-align: 0.3em;
        @include pc() {
            font-size: rem(20);
            line-height: rem(29);
            padding: 0 rem(6) rem(3);
            margin-left: rem(6);
        }
    }
}

.intro__venue {
    font-size: rem(28);
    font-weight: $bold;
    line-height: 1.6;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    justify-content: center;
    gap: rem(15);
    margin-bottom: rem(40);
    @include pc() {
        font-size: rem(48);
    }
    &:before,
    &:after {
        content: '';
        background-color: $white;
        height: 1px;
        width: 100%;
        max-width: rem(140);
    }
    &:before {
        margin-left: auto;
    }
    span {
        white-space: nowrap;
    }
}

.intro__desc {
    font-size: rem(15);
    line-height: 2.4;
    text-align: left;
    text-transform: uppercase;
    @include pc() {
        text-align: center;
        font-size: rem(18);
    }
}

.intro__box {
    border: 1px solid $white;
    padding: rem(16);
    display: flex;
    flex-direction: column;
    gap: rem(10);
    text-align: center;
    margin-block: rem(80);
}

.intro__heading {
    font-size: rem(20);
    font-weight: 700;
}

.intro__goods {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: rem(40);
    h3 {
        font-size: rem(18);
        @include pc {
            font-size: rem(20);
        }
    }
    p {
        line-height: 1.8;
        text-align: center;
    }
    a {
        text-transform: none;
        color: $blue;
        line-height: 1.6;
        font-weight: $normal;
        font-size: rem(14);
        display: block;
        @include pc() {
            font-size: rem(16);
        }
    }
}

.intro__block {
    display: flex;
    flex-direction: column;
    gap: rem(16);
}
