@use 'global' as *;
@use 'sass:math';

.footer {
  background-color: $white;
}

.footer__inner {
  padding-block: rem(40) rem(24);
}

.footer__copyright {
  color: $black;
  text-align: center;
  font-size: rem(12);
}

.footer__logo {
  max-width: rem(175);
  width: 100%;
  margin-bottom: rem(24);
  margin-inline: auto;
}
