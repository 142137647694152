@use 'global' as *;
@use 'sass:math';

.sns__inner {
  display: flex;
  flex-direction: column;
  gap: rem(24);
  @include pc() {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: rem(20);
  }
}

.sns__container {
  background-color: $black;
  padding: rem(40) rem(16);
  @include pc() {
    padding: rem(40);
  }

  iframe {
    width: auto;
    @include pc() {
      width: 100%;
      max-width: 470px;
    }
  }
}

.sns__title {
  margin-bottom: rem(24);
}

.instagram__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: rem(4);
  @include pc() {
    gap: rem(10);
  }
}

.instagram__profile {
  display: grid;
  grid-template-columns: rem(80) 1fr;
  align-items: center;
  gap: rem(16);
}
