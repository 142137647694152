@use 'sass:math';
// @import url('https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@700&display=swap');

////////////////////
// フォント設定
////////////////////
$base-font: 'Noto Sans JP', sans-serif;
$en-font: 'Cormorant Garamond', serif;

// フォントウェイト
$normal: 400;
$semibold: 600;
$bold: 700;

////////////////////
// 色の指定
////////////////////
$black: #000;
$white: #fff;
$text: #EFEFEF;
$blue: #4AC1CD;
$gradation: linear-gradient(90deg, #49C1CC 2.01%, #5889C2 51.01%, #AD368C 100%);

@mixin gradationText() {
  background: $gradation;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

////////////////////
// メディアクエリとブレークポイントの設定
////////////////////

$breakpoints: (
  sm: 600,
  md: 768,
  lg: 1024,
  xl: 1440,
  sp: 767
);


//メディアクエリー
$mediaqueries: (
  sm: "screen and (min-width: #{map-get($breakpoints,'sm')}px)",
  md: "screen and (min-width: #{map-get($breakpoints,'md')}px)",
  lg: "screen and (min-width: #{map-get($breakpoints,'lg')}px)",
  xl: "screen and (min-width: #{map-get($breakpoints,'xl')}px)",
  sp: "screen and (max-width: #{map-get($breakpoints,'sp')}px)",
);

@mixin pc() {
  @media #{map-get($mediaqueries, 'md')} {
    @content;
  }
}
@mixin lg() {
  @media #{map-get($mediaqueries, 'lg')} {
    @content;
  }
}
@mixin sp() {
  @media #{map-get($mediaqueries, 'sp')} {
    @content;
  }
}

@function rem($pixels) {
  @return math.div($pixels, 16) * 1rem;
}

@custom-media --hover (hover: hover) and (pointer: fine);

@mixin hover() {
  &:hover,
  &:focus-visible {
    @content;
  }
}

////////////////////
// 非表示設定
////////////////////

//768px以上を表示
.u-desktop {
  display: none;
  visibility: hidden;
  @include pc() {
    display: block;
    visibility: visible;
  }
}

//モバイルのみ表示
.u-mobile {
  @include pc() {
    display: none !important;
    visibility: hidden;
  }
}
