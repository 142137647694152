@use 'global' as *;
@use 'sass:math';

.contact__container {
    background-color: $black;
    padding: rem(40) rem(16);
    display: flex;
    flex-direction: column;
    gap: rem(24);
    align-items: center;
    @include pc() {
        gap: rem(40);
        padding-block: rem(80);
    }
}

.contact__btn {
    max-width: 335px;
    width: 100%;
}
