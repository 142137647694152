@use 'global' as *;

.btn {
    background: $gradation;
    color: $white;
    text-align: center;
    display: block;
    width: 100%;
    line-height: rem(50);
    border-radius: 50vh;
    font-weight: $bold;
    font-size: rem(15);
    overflow: hidden;
    position: relative;
    @include pc() {
        font-size: rem(16);
    }
    &:before {
        background: $blue;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: .3s ease-out;
        width: 100%;
        display: inline-block;
    }
    @media (--hover) {
        &:before {
            transition: .3s ease-out;
        }
        @include hover {
            &:before {
                opacity: 0;
            }
        }
    }

    span {
        position: relative;
    }
}
