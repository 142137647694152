@use "global" as *;
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@600&family=Noto+Sans+JP:wght@400;700&display=swap');

html {
  font-size: rem(16);
  color: $white;
  background-color: $black;
  font-family: $base-font;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

/* hover */
a {
  text-decoration: none;
  color: inherit;
}

img {
  width: 100%;
  display: block;
}
