@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@600&family=Noto+Sans+JP:wght@400;700&display=swap");
@custom-media --hover (hover: hover) and (pointer: fine);
.u-desktop {
  display: none;
  visibility: hidden;
}
@media screen and (min-width: 768px) {
  .u-desktop {
    display: block;
    visibility: visible;
  }
}

@media screen and (min-width: 768px) {
  .u-mobile {
    display: none !important;
    visibility: hidden;
  }
}

html {
  font-size: 1rem;
  color: #fff;
  background-color: #000;
  font-family: "Noto Sans JP", sans-serif;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

/* hover */
a {
  text-decoration: none;
  color: inherit;
}

img {
  width: 100%;
  display: block;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul,
ol {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
  width: 100%;
  max-width: 100%;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul,
ol {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
  width: 100%;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Blur images when they have no alt attribute */
img:not([alt]) {
  filter: blur(10px);
}

/* フォームリセット */
input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

textarea {
  resize: vertical;
}

input[type=checkbox],
input[type=radio] {
  display: none;
}

input[type=submit],
input[type=button],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

.btn {
  background: linear-gradient(90deg, #49C1CC 2.01%, #5889C2 51.01%, #AD368C 100%);
  color: #fff;
  text-align: center;
  display: block;
  width: 100%;
  line-height: 3.125rem;
  border-radius: 50vh;
  font-weight: 700;
  font-size: 0.9375rem;
  overflow: hidden;
  position: relative;
}
@media screen and (min-width: 768px) {
  .btn {
    font-size: 1rem;
  }
}
.btn:before {
  background: #4AC1CD;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: 0.3s ease-out;
  width: 100%;
  display: inline-block;
}
@media (--hover) {
  .btn:before {
    transition: 0.3s ease-out;
  }
  .btn:hover:before, .btn:focus-visible:before {
    opacity: 0;
  }
}
.btn span {
  position: relative;
}

.contact__container {
  background-color: #000;
  padding: 2.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .contact__container {
    gap: 2.5rem;
    padding-block: 5rem;
  }
}

.contact__btn {
  max-width: 335px;
  width: 100%;
}

.contents p {
  color: #EFEFEF;
}

@media screen and (min-width: 768px) {
  .contents__inner.inner {
    max-width: 930px;
  }
}

.contents__title {
  margin-bottom: 1.75rem;
  margin-inline: auto;
}

.contents__heading {
  text-align: center;
  font-size: 1.125rem;
}
@media screen and (min-width: 768px) {
  .contents__heading {
    font-size: 1.25rem;
  }
}

.contents__grid {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding-bottom: 5rem;
}
@media screen and (min-width: 768px) {
  .contents__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem 1.25rem;
  }
}

.contents__gridItem {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.contents__gridItem h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .contents__gridItem h3 {
    height: 8.5rem;
  }
}
@media screen and (min-width: 1024px) {
  .contents__gridItem h3 {
    font-size: 1.125rem;
    height: 6.4375rem;
  }
}
@media screen and (max-width: 767px) {
  .contents__gridItem h3 {
    font-size: 1.125rem;
  }
}
.contents__gridItem p {
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  line-height: 1.8;
}
.contents__gridItem span {
  font-size: 0.75rem;
}
.contents__gridItem a {
  line-height: 2rem;
}

.contents__content {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
.contents__content + .contents__content {
  margin-top: 5rem;
}
.contents__content p {
  font-size: 0.875rem;
  line-height: 1.8;
}
@media screen and (min-width: 768px) {
  .contents__content p {
    font-size: 1rem;
  }
}

.contents__imgs {
  display: flex;
  margin-inline: auto;
  gap: 2.5rem;
}
@media screen and (max-width: 767px) {
  .contents__imgs {
    flex-direction: column;
  }
}
.contents__imgs--sm {
  max-width: 640px;
}
.contents__imgs--md {
  max-width: 818px;
}

.contents__img {
  text-align: center;
}
@media screen and (max-width: 767px) {
  .contents__img {
    max-width: 445px;
    margin-inline: auto;
  }
}
@media screen and (max-width: 767px) {
  .contents__img--sm {
    max-width: 222px;
    width: 100%;
  }
}
.contents__img--md {
  max-width: 640px;
}
@media screen and (min-width: 768px) {
  .contents__img img {
    max-height: 296px;
  }
}

.contents__caption {
  font-size: 0.875rem;
  text-align: center;
  display: block;
  margin-top: 0.5rem;
  color: #EFEFEF;
}

.contents__box {
  padding: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #EFEFEF;
  font-size: 0.875rem;
  line-height: 1.8;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.contents__box dl {
  display: grid;
  grid-template-columns: 4.375rem 1fr;
}
.contents__box ol {
  counter-reset: number 0;
}
.contents__box ol li {
  padding-left: 1em;
  text-indent: -1em;
}
.contents__box ol li:before {
  counter-increment: number 1;
  content: counter(number) ".";
}
.contents__box a {
  border-bottom: 1px solid #fff;
  word-wrap: break-word;
}
.contents__box p {
  font-size: 0.875rem;
}

.contents__list li {
  padding-left: 1em;
  text-indent: -1em;
}
.contents__list li:before {
  content: "・";
}

.contents__item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.contents__item sup {
  font-size: 0.625rem;
}

.contents__cast {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
@media screen and (min-width: 768px) {
  .contents__cast {
    flex-direction: row;
  }
}
.contents__cast figure {
  max-width: 220px;
  flex-shrink: 0;
  margin-inline: auto;
}

.contents__castText {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  font-size: 0.875rem;
  line-height: 1.8;
}
.contents__castText p {
  font-size: 0.875rem;
}

.contents__castComment {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

p.contents__castQuestion {
  color: #CECECE;
}

.faq {
  padding-top: 4rem;
}
@media screen and (min-width: 768px) {
  .faq {
    padding-top: 5rem;
  }
}

@media screen and (min-width: 768px) {
  .faq__inner.inner {
    max-width: 930px;
  }
}

.faq__title {
  margin-bottom: 2rem;
  margin-inline: auto;
}
@media screen and (min-width: 768px) {
  .faq__title {
    margin-bottom: 2.5rem;
  }
}

.faq__list {
  display: flex;
  flex-direction: column;
}

.faq__item {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  padding-block: 1.25rem;
  color: #EFEFEF;
}
@media screen and (min-width: 768px) {
  .faq__item {
    gap: 1rem;
    padding-block: 1.5rem;
  }
}
.faq__item:not(:first-child) {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.faq__question,
.faq__answer {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.faq__question:before,
.faq__answer:before {
  text-transform: uppercase;
  font-family: "Cormorant Garamond", serif;
  font-weight: 600;
  font-size: 2rem;
}
@media screen and (min-width: 768px) {
  .faq__question:before,
  .faq__answer:before {
    font-size: 2.5rem;
  }
}

.faq__question {
  font-size: 1rem;
  font-weight: 700;
}
@media screen and (min-width: 768px) {
  .faq__question {
    font-size: 1.25rem;
  }
}
.faq__question:before {
  content: "q";
  background: linear-gradient(90deg, #49C1CC 2.01%, #5889C2 51.01%, #AD368C 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.faq__answer {
  font-size: 0.875rem;
  line-height: 1.6;
}
@media screen and (min-width: 768px) {
  .faq__answer {
    font-size: 1rem;
  }
}
.faq__answer:before {
  content: "a";
  color: #4AC1CD;
}

.footer {
  background-color: #fff;
}

.footer__inner {
  padding-block: 2.5rem 1.5rem;
}

.footer__copyright {
  color: #000;
  text-align: center;
  font-size: 0.75rem;
}

.footer__logo {
  max-width: 10.9375rem;
  width: 100%;
  margin-bottom: 1.5rem;
  margin-inline: auto;
}

.header {
  position: fixed;
  background-color: #000;
  z-index: 100;
  width: 100%;
  height: 2.9375rem;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .header {
    height: 3.6875rem;
  }
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 1rem;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .header__inner {
    padding-inline: 1.25rem;
  }
}

@media screen and (max-width: 767px) {
  .header__nav {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000;
    top: 0;
    right: -120%;
    transition: 0.4s ease-out;
  }
  .header__nav.is-active {
    right: 0;
    transition: 0.4s ease-out;
  }
}

.header__list {
  display: flex;
  gap: 2.5rem;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .header__list {
    flex-direction: column;
    position: absolute;
    top: calc(50% + 23px);
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.header__item a {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Cormorant Garamond", serif;
}
@media screen and (max-width: 767px) {
  .header__item a {
    line-height: 1.2;
    font-size: 2.5rem;
    background: linear-gradient(90deg, #49C1CC 2.01%, #5889C2 51.01%, #AD368C 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
@media screen and (min-width: 768px) {
  .header__item a {
    font-size: 1rem;
  }
}
@media (--hover) {
  .header__item a {
    transition: 0.3s ease-out;
  }
  .header__item a:hover, .header__item a:focus-visible {
    opacity: 0.8;
    transition: 0.3s ease-out;
  }
}

.header__logo {
  max-width: 14.9375rem;
  width: 100%;
  position: relative;
  z-index: 90;
}

.header__hamburger {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.8125rem;
  right: 0.875rem;
  z-index: 90;
}
@media (--hover) {
  .header__hamburger:hover, .header__hamburger:focus-visible {
    cursor: pointer;
  }
}
.header__hamburger span {
  position: absolute;
  background-color: #fff;
  height: 0.0625rem;
  width: 1rem;
  transition: 0.3s ease-out;
}
.header__hamburger span:first-child {
  top: 0.3125rem;
}
.header__hamburger span:nth-child(2) {
  top: 0.5625rem;
}
.header__hamburger span:nth-child(3) {
  top: 0.8125rem;
}
.header__hamburger.is-active span {
  transition: 0.3s ease-out;
}
.header__hamburger.is-active span:first-child {
  transform: translateY(5px) rotate(-45deg);
}
.header__hamburger.is-active span:nth-child(2) {
  opacity: 0;
}
.header__hamburger.is-active span:nth-child(3) {
  transform: translateY(-3px) rotate(45deg);
}
@media screen and (min-width: 768px) {
  .header__hamburger {
    display: none;
  }
}

.inner {
  max-width: 1120px;
  width: calc(100% - 2rem);
  margin-inline: auto;
}

.intro {
  padding-block: 4.0625rem 5rem;
}
@media screen and (min-width: 768px) {
  .intro {
    padding-block: 5rem;
  }
}

@media screen and (min-width: 768px) {
  .intro__inner {
    max-width: 930px;
  }
}

.intro__date {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.6;
}
@media screen and (max-width: 767px) {
  .intro__date {
    margin-bottom: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .intro__date {
    font-size: 2rem;
  }
}
.intro__dateYear {
  font-size: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .intro__dateYear {
    font-size: 1.25rem;
    margin-right: 0.1875rem;
  }
}
.intro__dateNum {
  font-size: 1.75rem;
}
@media screen and (min-width: 768px) {
  .intro__dateNum {
    font-size: 3rem;
    margin-inline: 0.1875rem;
  }
}
.intro__datePeriod {
  margin-inline: 0.25rem 0.125rem;
}
@media screen and (min-width: 768px) {
  .intro__datePeriod {
    margin-inline: 0.625rem 0.4375rem;
  }
}
.intro__dateDay {
  background-color: #fff;
  border-radius: 50%;
  color: #000;
  font-size: 0.875rem;
  padding: 0 0.25rem 0.125rem;
  margin-left: 0.125rem;
  line-height: 1.25rem;
  vertical-align: 0.3em;
}
@media screen and (min-width: 768px) {
  .intro__dateDay {
    font-size: 1.25rem;
    line-height: 1.8125rem;
    padding: 0 0.375rem 0.1875rem;
    margin-left: 0.375rem;
  }
}

.intro__venue {
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.6;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  justify-content: center;
  gap: 0.9375rem;
  margin-bottom: 2.5rem;
}
@media screen and (min-width: 768px) {
  .intro__venue {
    font-size: 3rem;
  }
}
.intro__venue:before, .intro__venue:after {
  content: "";
  background-color: #fff;
  height: 1px;
  width: 100%;
  max-width: 8.75rem;
}
.intro__venue:before {
  margin-left: auto;
}
.intro__venue span {
  white-space: nowrap;
}

.intro__desc {
  font-size: 0.9375rem;
  line-height: 2.4;
  text-align: left;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .intro__desc {
    text-align: center;
    font-size: 1.125rem;
  }
}

.intro__box {
  border: 1px solid #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  text-align: center;
  margin-block: 5rem;
}

.intro__heading {
  font-size: 1.25rem;
  font-weight: 700;
}

.intro__goods {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
.intro__goods h3 {
  font-size: 1.125rem;
}
@media screen and (min-width: 768px) {
  .intro__goods h3 {
    font-size: 1.25rem;
  }
}
.intro__goods p {
  line-height: 1.8;
  text-align: center;
}
.intro__goods a {
  text-transform: none;
  color: #4AC1CD;
  line-height: 1.6;
  font-weight: 400;
  font-size: 0.875rem;
  display: block;
}
@media screen and (min-width: 768px) {
  .intro__goods a {
    font-size: 1rem;
  }
}

.intro__block {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.main {
  padding-top: 2.9375rem;
}
@media screen and (min-width: 768px) {
  .main {
    padding-top: 3.6875rem;
  }
}

@media screen and (min-width: 768px) {
  .outline__inner {
    max-width: 930px;
  }
}

.outline__title {
  margin-bottom: 2rem;
  margin-inline: auto;
}
@media screen and (min-width: 768px) {
  .outline__title {
    margin-bottom: 2.5rem;
  }
}

.outline__list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media screen and (min-width: 768px) {
  .outline__list {
    gap: 2.5rem;
  }
}

.outline__contentDetail {
  text-align: left;
  font-weight: 400;
  line-height: 1.6;
  display: flex;
}
@media screen and (max-width: 767px) {
  .outline__contentDetail {
    font-size: 0.875rem;
  }
}
@media screen and (min-width: 768px) {
  .outline__contentDetail {
    max-width: 520px;
    width: 100%;
    margin-inline: auto;
    font-size: 1rem;
  }
}
.outline__contentDetail p:first-child {
  flex-shrink: 0;
}

.section {
  padding-block: 4rem;
}
@media screen and (min-width: 768px) {
  .section {
    padding-block: 5rem;
  }
}
.section--bg {
  background-image: url(../img/bg.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.section__item {
  font-weight: 700;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .section__item {
    font-size: 1.25rem;
  }
}

.section__heading {
  background: linear-gradient(90deg, #49C1CC 2.01%, #5889C2 51.01%, #AD368C 100%);
  padding: 0.5rem 1rem;
  color: #fff;
}

.section__content {
  text-transform: uppercase;
  padding: 1.5rem 1rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  color: #000;
  line-height: 1.45;
}
@media screen and (min-width: 768px) {
  .section__content {
    padding: 2.5rem;
    gap: 1rem;
  }
}
.section__content a {
  text-transform: none;
  color: #4AC1CD;
  line-height: 1.6;
  font-weight: 400;
  font-size: 0.875rem;
}
@media screen and (min-width: 768px) {
  .section__content a {
    font-size: 1rem;
  }
}

.sns__inner {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
@media screen and (min-width: 768px) {
  .sns__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem;
  }
}

.sns__container {
  background-color: #000;
  padding: 2.5rem 1rem;
}
@media screen and (min-width: 768px) {
  .sns__container {
    padding: 2.5rem;
  }
}
.sns__container iframe {
  width: auto;
}
@media screen and (min-width: 768px) {
  .sns__container iframe {
    width: 100%;
    max-width: 470px;
  }
}

.sns__title {
  margin-bottom: 1.5rem;
}

.instagram__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.25rem;
}
@media screen and (min-width: 768px) {
  .instagram__list {
    gap: 0.625rem;
  }
}

.instagram__profile {
  display: grid;
  grid-template-columns: 5rem 1fr;
  align-items: center;
  gap: 1rem;
}

p.text-sm {
  font-size: 0.875rem;
}

.mb-10 {
  margin-bottom: 0.625rem;
}

@media screen and (min-width: 768px) {
  .ticket__inner {
    max-width: 930px;
  }
}

.ticket__title {
  margin-bottom: 1.75rem;
  margin-inline: auto;
}

.ticket__content dl {
  display: flex;
  gap: 0.625rem;
  flex-direction: column;
  align-items: flex-start;
}
@media screen and (min-width: 768px) {
  .ticket__content dl {
    max-width: 46.25rem;
    margin-inline: auto;
    gap: 2.5rem 1.875rem;
    display: grid;
    grid-template-columns: 5.875rem 1fr;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.ticket__content dd {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
@media screen and (min-width: 768px) {
  .ticket__content dd {
    gap: 1.5rem;
  }
}

.ticket__contentLabel {
  background-color: #000;
  color: #fff;
  line-height: 1.625rem;
  width: 4.75rem;
  font-size: 0.875rem;
}
@media screen and (max-width: 767px) {
  .ticket__contentLabel:not(:first-child) {
    margin-top: 0.625rem;
  }
}
@media screen and (min-width: 768px) {
  .ticket__contentLabel {
    line-height: 1.75rem;
    width: 100%;
    font-size: 1rem;
  }
}

.ticket__contentDetail {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}
.ticket__contentDetail span {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.6;
}
@media screen and (min-width: 768px) {
  .ticket__contentDetail span {
    font-size: 1rem;
  }
}

.ticket__contentAttention {
  display: block;
}
@media screen and (min-width: 768px) {
  .ticket__contentAttention {
    display: flex;
  }
}
.ticket__contentAttention:before {
  content: "※";
}

.ticket__contentDetailLabel {
  font-size: 0.875rem;
  line-height: 1.6;
}
@media screen and (min-width: 768px) {
  .ticket__contentDetailLabel {
    font-size: 1rem;
  }
}

.title {
  width: fit-content;
  font-size: 2.5rem;
  background: linear-gradient(90deg, #49C1CC 2.01%, #5889C2 51.01%, #AD368C 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Cormorant Garamond", serif;
  line-height: 1.2;
}
@media screen and (min-width: 768px) {
  .title {
    font-size: 3rem;
  }
}