@use 'global' as *;
@use 'sass:math';

.header {
  position: fixed;
  background-color: $black;
  z-index: 100;
  width: 100%;
  height: rem(47);
  display: flex;
  align-items: center;
  @include pc() {
    height: rem(59);
  }
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: rem(16);
  width: 100%;
  @include pc() {
    padding-inline: rem(20);
  }
}

.header__nav {
  @include sp() {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: $black;
    top: 0;
    right: -120%;
    transition: .4s ease-out;
    &.is-active {
      right: 0;
      transition: .4s ease-out;
    }
  }
}

.header__list {
  display: flex;
  gap: rem(40);
  align-items: center;
  @include sp() {
    flex-direction: column;
    position: absolute;
    top: calc(50% + 23px);
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.header__item a {
  display: inline-block;
  text-transform: uppercase;
  font-weight: $semibold;
  font-family: $en-font;
  @include sp() {
    line-height: 1.2;
    font-size: rem(40);
    @include gradationText;
  }
  @include pc() {
    font-size: rem(16);
  }
  @media (--hover) {
    transition: .3s ease-out;
    @include hover {
      opacity: .8;
      transition: .3s ease-out;
    }
  }
}

.header__logo {
  max-width: rem(239);
  width: 100%;
  position: relative;
  z-index: 90;
}

.header__hamburger {
  width: rem(20);
  height: rem(20);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: rem(13);
  right: rem(14);
  z-index: 90;
  @media (--hover) {
    @include hover {
      cursor: pointer;
    }
  }

  span {
    position: absolute;
    background-color: $white;
    height: rem(1);
    width: rem(16);
    transition: 0.3s ease-out;
    &:first-child {
      top: rem(5);
    }
    &:nth-child(2) {
      top: rem(9);
    }
    &:nth-child(3) {
      top: rem(13);
    }
  }

  &.is-active {
    span {
      transition: 0.3s ease-out;
      &:first-child {
        transform: translateY(5px) rotate(-45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: translateY(-3px) rotate(45deg);
      }
    }
  }
  @include pc() {
    display: none;
  }
}
