@use 'global' as *;
@use 'sass:math';

.ticket__inner {
    @include pc() {
        max-width: 930px;
    }
}

.ticket__title {
    margin-bottom: rem(28);
    margin-inline: auto;
}

.ticket__content {
    dl {
        display: flex;
        gap: rem(10);
        flex-direction: column;
        align-items: flex-start;
        @include pc() {
            max-width: rem(740);
            margin-inline: auto;
            gap: rem(40) rem(30);
            display: grid;
            grid-template-columns: rem(94) 1fr;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
    dd {
        display: flex;
        flex-direction: column;
        gap: rem(20);
        @include pc() {
            gap: rem(24);
        }
    }
}

.ticket__contentLabel {
    background-color: $black;
    color: $white;
    line-height: rem(26);
    width: rem(76);
    font-size: rem(14);
    &:not(:first-child) {
        @include sp() {
            margin-top: rem(10);
        }
    }
    @include pc() {
        line-height: rem(28);
        width: 100%;
        font-size: rem(16);
    }
}

.ticket__contentDetail {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: rem(10);
    span {
        font-weight: 400;
        font-size: rem(14);
        line-height: 1.6;
        @include pc() {
            font-size: rem(16);
        }
    }
}

.ticket__contentAttention {
    display: block;
    @include pc() {
        display: flex;
    }
    &:before {
        content: '※';
    }
}

.ticket__contentDetailLabel {
    font-size: rem(14);
    line-height: 1.6;
    @include pc() {
        font-size: rem(16);
    }
}
