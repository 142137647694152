@use 'global' as *;
@use 'sass:math';

p.text-sm {
  font-size: rem(14);
}

.mb-10 {
  margin-bottom: rem(10);
}
