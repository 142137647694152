@use 'global' as *;
@use 'sass:math';

.faq {
    padding-top: rem(64);
    @include pc() {
        padding-top: rem(80);
    }
}

.faq__inner.inner {
    @include pc() {
        max-width: 930px;
    }
}

.faq__title {
    margin-bottom: rem(32);
    margin-inline: auto;
    @include pc() {
        margin-bottom: rem(40);
    }
}

.faq__list {
    display: flex;
    flex-direction: column;
}

.faq__item {
    display: flex;
    flex-direction: column;
    gap: rem(10);
    padding-block: rem(20);
    color: $text;
    @include pc {
        gap: rem(16);
        padding-block: rem(24);
    }
    &:not(:first-child) {
        border-top: 1px solid rgba($white, .5);
    }
}

.faq__question,
.faq__answer {
    display: flex;
    align-items: center;
    gap: rem(16);
    &:before {
        text-transform: uppercase;
        font-family: $en-font;
        font-weight: $semibold;
        font-size: rem(32);
        @include pc {
            font-size: rem(40);
        }
    }
}

.faq__question {
    font-size: rem(16);
    font-weight: 700;
    @include pc {
        font-size: rem(20);
    }
    &:before {
        content: 'q';
        @include gradationText;
    }
}

.faq__answer {
    font-size: rem(14);
    @include pc {
        font-size: rem(16);
    }
    line-height: 1.6;
    &:before {
        content: 'a';
        color: $blue;
    }
}
