@use 'global' as *;
@use 'sass:math';

.contents p {
    color: $text;
}

.contents__inner.inner {
    @include pc() {
        max-width: 930px;
    }
}

.contents__title {
    margin-bottom: rem(28);
    margin-inline: auto;
}

.contents__heading {
    text-align: center;
    font-size: rem(18);
    @include pc {
        font-size: rem(20);
    }
}

.contents__grid {
    display: flex;
    flex-direction: column;
    gap: rem(40);
    padding-bottom: rem(80);
    @include pc {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: rem(40) rem(20);
    }
}

.contents__gridItem {
    display: flex;
    flex-direction: column;
    gap: rem(20);
    h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem(8);
        font-size: rem(16);
        @include pc {
            height: rem(136);
        }
        @include lg {
            font-size: rem(18);
            height: rem(103);
        }
        @include sp {
            font-size: rem(18);
        }
    }
    p {
        font-size: rem(14);
        display: flex;
        flex-direction: column;
        gap: rem(8);
        line-height: 1.8;
    }
    span {
        font-size: rem(12);
    }
    a {
        line-height: rem(32);
    }
}

.contents__content {
    display: flex;
    flex-direction: column;
    gap: rem(40);
    & + & {
        margin-top: rem(80);
    }

    p {
        font-size: rem(14);
        line-height: 1.8;
        @include pc() {
            font-size: rem(16);
        }
    }
}

.contents__imgs {
    display: flex;
    margin-inline: auto;
    gap: rem(40);
    @include sp {
        flex-direction: column;
    }
    &--sm {
        max-width: 640px;
    }
    &--md {
        max-width: 818px;
    }
}

.contents__img {
    text-align: center;
    @include sp {
        max-width: 445px;
        margin-inline: auto;
    }
    &--sm {
        @include sp {
            max-width: 222px;
            width: 100%;
        }
    }
    &--md {
        max-width: 640px;
    }
    img {
        @include pc {
            max-height: 296px;
        }
    }
}

.contents__caption {
    font-size: rem(14);
    text-align: center;
    display: block;
    margin-top: rem(8);
    color: $text;
}

.contents__box {
    padding: rem(16);
    border: 1px solid rgba(255, 255, 255, 0.50);
    color: #EFEFEF;
    font-size: rem(14);
    line-height: 1.8;
    display: flex;
    flex-direction: column;
    gap: rem(16);
    dl {
        display: grid;
        grid-template-columns: rem(70) 1fr;
    }
    ol {
        counter-reset: number 0;
        li {
            padding-left: 1em;
            text-indent: -1em;
        }
        li:before {
            counter-increment: number 1;
            content: counter(number)'.';
        }
    }
    a {
        border-bottom: 1px solid $white;
        word-wrap: break-word;
    }
    p {
        font-size: rem(14);
    }
}

.contents__list {
    li {
        padding-left: 1em;
        text-indent: -1em;
        &:before {
            content: '・';
        }
    }
}

.contents__item {
    display: flex;
    flex-direction: column;
    gap: rem(8);
    sup {
        font-size: rem(10);
    }
}

.contents__cast {
    display: flex;
    flex-direction: column;
    gap: rem(20);
    @include pc {
        flex-direction: row;
    }
    figure {
        max-width: 220px;
        flex-shrink: 0;
        margin-inline: auto;
    }
}

.contents__castText {
    display: flex;
    flex-direction: column;
    gap: rem(20);
    font-size: rem(14);
    line-height: 1.8;
    p {
        font-size: rem(14);
    }
}

.contents__castComment {
    display: flex;
    flex-direction: column;
    gap: rem(8);
}

p.contents__castQuestion {
    color: #CECECE;
}
