@use 'global' as *;
@use 'sass:math';

.outline__inner {
    @include pc() {
        max-width: 930px;
    }
}

.outline__title {
    margin-bottom: rem(32);
    margin-inline: auto;
    @include pc() {
        margin-bottom: rem(40);
    }
}

.outline__list {
    display: flex;
    flex-direction: column;
    gap: rem(16);
    @include pc() {
        gap: rem(40);
    }
}

.outline__contentDetail {
    text-align: left;
    font-weight: $normal;
    line-height: 1.6;
    display: flex;
    @include sp() {
        font-size: rem(14);
    }
    @include pc() {
        max-width: 520px;
        width: 100%;
        margin-inline: auto;
        font-size: rem(16);
    }
    p:first-child {
        flex-shrink: 0;
    }
}
