@use 'global' as *;
@use 'sass:math';

.section {
    padding-block: rem(64);
    @include pc() {
        padding-block: rem(80);
    }
    &--bg {
        background-image: url(../img/bg.jpg);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.section__item {
    font-weight: $bold;
    text-align: center;
    @include pc() {
        font-size: rem(20);
    }
}

.section__heading {
    background: $gradation;
    padding: rem(8) rem(16);
    color: $white;
}

.section__content {
    text-transform: uppercase;
    padding: rem(24) rem(16);
    background-color: $white;
    display: flex;
    flex-direction: column;
    gap: rem(10);
    color: $black;
    line-height: 1.45;
    @include pc() {
        padding: rem(40);
        gap: rem(16);
    }
    a {
        text-transform: none;
        color: $blue;
        line-height: 1.6;
        font-weight: $normal;
        font-size: rem(14);
        @include pc() {
            font-size: rem(16);
        }
    }
}
