@use 'global' as *;
@use 'sass:math';

.title {
  width: fit-content;
  font-size: rem(40);
  @include gradationText;
  font-weight: $semibold;
  text-transform: uppercase;
  font-family: $en-font;
  line-height: 1.2;
  @include pc() {
    font-size: rem(48);
  }
}
